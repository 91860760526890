import axios from '@axios'
import endpoints from '@enums/endpoints'

export default {
  /**
   * Fetch bidders values
   * @param {Object} params
   * @returns {Promise<AxiosResponse<any>>}
   */
  getBidders (params) {
    return axios.get(endpoints.BIDDERS, { params })
  },
  /**
   * Get bidder values
   * @param {string} bidderIRI
   * @returns {Promise<AxiosResponse<any>>}
   */
  getBidder (bidderIRI) {
    return axios.get(bidderIRI)
  },
  /**
   * Update bidder values
   * @param {string} bidderIRI
   * @param {Object} data
   * @returns {Promise<AxiosResponse<any>>}
   */
  updateBidder (bidderIRI, data) {
    return axios.patch(bidderIRI, data)
  },
  /**
   * Create bidder
   * @param {Object} data
   * @returns {Promise<AxiosResponse<any>>}
   */
  createBidder (data) {
    return axios.post(endpoints.BIDDERS, data)
  }
}
