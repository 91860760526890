export const state = {
  items: undefined,
  loading: false
}

export const getters = {}

export const mutations = {
  set (state, { key, value }) {
    state[key] = value
  }
}

export const actions = {
  /**
   * Fetch items values
   * @param commit
   * @param {Object} [params={}]
   * @returns {Promise<any>}
   */
  async fetchItems ({ commit }, params = {}) {
    try {
      commit('set', {
        key: 'loading',
        value: true
      })
      const { data: { 'hydra:member': websiteProductsItems } } = await this.$services.websitesService.getWebsiteProducts(params)
      commit('set', {
        key: 'items',
        value: websiteProductsItems || undefined
      })

      return websiteProductsItems
    } catch (error) {
      return Promise.reject(error)
    } finally {
      commit('set', {
        key: 'loading',
        value: false
      })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
