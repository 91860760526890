export default Object.freeze({
  ACCOUNT: 'account',
  ADMINISTRATION: 'administration',
  ALL_ACL: 'all-acl',
  ALL_ORGANIZATIONS: 'all_organizations',
  ALL_USERS: 'all_users',
  ALL_WEBSITES: 'all_websites',
  ALL_WEBSITE_PRODUCTS: 'all_website_products',
  ANALYTICS: 'analytics',
  ANY: 'any', // used for super_admin with aclActions.ACTION
  API_TOKEN: 'api_token',
  CONTRACT: 'contract',
  COUNTRY: 'country',
  DEFAULT_ADSTXT: 'default_adstxt',
  DEFAULT_BIDDERS: 'default_bidders',
  GENDER: 'gender',
  MANAGEMENT: 'management',
  ORGANIZATION: 'organization',
  ORGANIZATION_EXCLUSION: 'organization_exclusion',
  ORGANIZATION_INVITATION: 'organization_invitation',
  ORGANIZATION_TYPE: 'organization_type',
  ROLE: 'role',
  USER: 'user',
  USER_GROUP: 'user_group',
  USER_HAS_ORGANIZATIONS: 'user_has_organizations',
  USER_HAS_WEBSITES: 'user_has_websites',
  VALIDATION_EMAIL: 'validation_email',
  WEBSITE: 'website',
  WEBSITE_ADSTXT_MONITORING: 'website_adstxt_monitoring',
  WEBSITE_BIDDERS: 'website_bidders',
  WEBSITE_CONFIG: 'website_config',
  WEBSITE_CONFIG_FORCE_UNLOCK: 'website_config_force_unlock',
  WEBSITE_CONFIG_LOCK: 'website_config_lock',
  WORDPRESS_TOKEN_DEBUG: 'wordpress_token_debug'
})
