import isObject from '@utils/isObject'
import config from '@enums/config'

export const state = {
  userData: undefined,
  defaultRole: 'Customer',
  rememberMyEmail: true,
  rememberedEmail: '',
  permissions: []
}

export const getters = {
  /**
   * @param state
   */
  fullName (state) {
    if (!isObject(state.userData)) {
      return ''
    }
    return `${state.userData.firstName} ${state.userData.lastName}`
  },
  /**
   * @param state
   */
  userRoles (state) {
    const userRoles = state.userData?.userGroups
    return userRoles && userRoles.length ? userRoles.map(userRole => userRole?.role?.label) : [state.defaultRole]
  },
  /**
   * @param state
   */
  isSuperAdmin (state) {
    const userRoles = state.userData?.userGroups
    if (!userRoles || userRoles.length === 0) return false

    return userRoles
      .map(userRole => userRole?.role?.name)
      .includes(config.ROLES.superAdmin)
  }
}

export const mutations = {
  /**
   * @param state
   * @param {Object} [user]
   */
  setUser (state, user) {
    state.userData = user
  },
  /**
   * @param state
   * @param {Object} [credentials]
   */
  setRememberMyEmail (state, credentials) {
    if (!credentials) return

    const { rememberMyEmail, email } = credentials || {}

    if (rememberMyEmail === undefined) return

    state.rememberMyEmail = rememberMyEmail
    state.rememberedEmail = rememberMyEmail ? email : ''
  },
  /**
   * @param state
   * @param {Object} permissions
   */
  setPermissions (state, { permissions: casbinPolicies }) {
    /**
     * Merge permissions with fields and common action, object values
     * @param {Array} permissions
     * @returns {unknown[]}
     */
    const groupPermissionsByFields = permissions => {
      const mergedObjects = {}

      permissions.forEach(item => {
        const key = `${item.action}_${item.object}`
        if (!mergedObjects[key]) {
          // Initialize a new object for unique combination
          mergedObjects[key] = {
            action: item.action,
            object: item.object,
            fields: item.fields ? [...item.fields] : undefined
          }
        } else {
          // Merge fields in existing array if exist
          // eslint-disable-next-line no-lonely-if
          if (item.fields) {
            mergedObjects[key].fields = mergedObjects[key].fields
              ? [...new Set([...mergedObjects[key].fields, ...item.fields])]
              : [...item.fields]
          }
        }
      })

      return Object.values(mergedObjects)
    }

    const userPermissions = casbinPolicies.map(policy => {
      // Default values with no field (pType === 'p')
      let action = policy?.v2
      let object = policy?.v1
      let fields = null

      // Policy with field
      if (policy?.pType === 'p2') {
        action = policy?.v3
        object = policy?.v1
        fields = [policy?.v2]
      }

      // Policy with field type value
      if (policy?.pType === 'p3') {
        action = policy?.v2
        object = policy?.v1
        fields = [`${policy?.v3}:${policy?.v4}`]
      }

      return {
        action,
        object,
        fields
      }
    })

    state.permissions = groupPermissionsByFields(userPermissions)
      .sort((a, b) => {
        if (a.action < b.action) return -1
        if (a.action > b.action) return 1
        return 0
      })
  }
}

export const actions = {
  /**
   * Send a PATCH request and update user stored in state with the request response
   * @param commit
   * @param state
   * @param {string} userIRI
   * @param {Object} data
   * @returns {Promise<*>}
   */
  async patchUser ({ commit, state }, { userIRI, data }) {
    const { data: patchedUser } = await this.$services.usersService.updateUser(
      userIRI,
      data
    )
    commit('setUser', patchedUser)
    return state.userData
  },
  /**
   * Send a POST request and update user stored in state with the new email
   * @param commit
   * @param state
   * @param {Object} payload
   */
  async updateEmail ({ commit, state }, payload) {
    await this.$services.usersService.updateEmail(payload)
    commit('setUser', {
      ...state.userData,
      email: payload.email
    })

    commit('setRememberMyEmail', {
      rememberMyEmail: state.rememberMyEmail,
      email: payload.email
    })
    return state.userData
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
