import axios from '@axios'
import endpoints from '@enums/endpoints'

export default {
  /**
   * Fetch contracts values
   * @param {Object} params
   * @returns {Promise<AxiosResponse<any>>}
   */
  getContracts (params) {
    return axios.get(endpoints.CONTRACTS, { params })
  },
  /**
   * Fetch contract values
   * @param {string} contractIRI
   * @returns {Promise<AxiosResponse<any>>}
   */
  getContract (contractIRI) {
    return axios.get(contractIRI)
  },
  /**
   * Update contract
   * @param {string} contractIRI
   * @param {Object} data
   * @returns {Promise<AxiosResponse<any>>}
   */
  updateContract (contractIRI, data) {
    return axios.patch(contractIRI, data)
  },
  /**
   * Publish contract
   * @param {string} contractIRI
   * @param {Object} data
   * @returns {Promise<AxiosResponse<any>>}
   */
  publishContract (contractIRI, data) {
    return axios.patch(endpoints.CONTRACTS_PUBLISH.replace('{{contractIRI}}', contractIRI), data || {})
  },
  /**
   * Delete contract
   * @param {string} contractIRI
   * @returns {Promise<AxiosResponse<any>>}
   */
  deleteContract (contractIRI) {
    return axios.delete(contractIRI)
  },
  /**
   * Duplicate contract
   * @param {string} contractIRI
   * @returns {Promise<AxiosResponse<any>>}
   */
  duplicateContract (contractIRI) {
    return axios.post(endpoints.DUPLICATED_CONTRACTS, { contract: contractIRI })
  },
  /**
   * Preview contract
   * @param {string} contractIRI
   * @returns {Promise<AxiosResponse<any>>}
   */
  previewContract (contractIRI) {
    return axios.get(endpoints.CONTRACTS_PREVIEW.replace('{{contractIRI}}', contractIRI))
  }
}
