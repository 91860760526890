import Vue from 'vue'
import VueI18n from 'vue-i18n'
import config from '@enums/config'
import { createI18n, castToVueI18n } from 'vue-i18n-bridge'
import { nextTick } from '@vue/composition-api'
import { createI18nMessage } from '@vuelidate/validators'
import datetimeFormats from './i18n-datetime-formats'
import numberFormats from './i18n-number-formats'

Vue.use(VueI18n, { bridge: true })

// Pattern language string
const defaultLanguage = config.DEFAULT_LANGUAGE
const navigatorLanguages = navigator.languages || [navigator.language]
const languages = navigatorLanguages[0]
let locale = localStorage.getItem(config.STORAGE_I18N) || languages || defaultLanguage
// Change pattern if navigatorLanguages isn't a string of 2 letters
locale = locale.length === 2 ? locale : locale.substring(0, 2)
// Verify if locale is allowed language
locale = config.LOCALES?.includes(locale) ? locale : defaultLanguage

export const i18n = castToVueI18n(
  createI18n(
    {
      locale,
      datetimeFormats,
      numberFormats,
      legacy: false,
      fallbackLocale: defaultLanguage,
      silentTranslationWarn: process.env.VUE_APP_ENVIRONMENT === 'production'
    },
    VueI18n
  )
)
/**
 * Set current language
 * @param {string} lang
 * @returns {string}
 */
function setI18nLanguage (lang) {
  i18n.locale = lang

  // eslint-disable-next-line no-param-reassign
  localStorage.setItem(config.STORAGE_I18N, lang.toString())

  return lang
}

/**
 * Check if i18n messages have been loaded
 * @param {string} lang
 * @returns {boolean}
 */
function isLocaleLoaded (lang) {
  return i18n.messages[lang] !== undefined && Object.keys(i18n.messages[lang]).length > 0
}

/**
 * Async loading language
 * @param {string} lang
 */
export async function loadLanguageAsync (lang) {
  // If the language was already loaded
  if (i18n.availableLocales.includes(lang) && isLocaleLoaded(lang)) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language hasn't been loaded yet
  const messages = await import(
    /* webpackChunkName: "lang-[request]" */ `./locales/${lang}.json`
  )

  i18n.setLocaleMessage(lang, messages.default)
  setI18nLanguage(lang)

  return nextTick()
}

export const withI18nMessage = createI18nMessage({ t: i18n.t.bind(i18n) })
