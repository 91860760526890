import axios, { getBounceUrl } from '@axios'
import endpoints from '@enums/endpoints'
import config from '@enums/config'

const registrationValidationUrl = getBounceUrl('/validate')
const resetPasswordUrl = getBounceUrl('/reset-password')

export default {
  /**
   * User registration
   * @param {Object} user
   * @param {string|null} [invitationIRI=null]
   * @returns {Promise<AxiosResponse<any>>}
   */
  register (user, invitationIRI = null) {
    const endpoint = invitationIRI
      ? endpoints.USERS_INVITATION.replace('{{invitationIRI}}', invitationIRI)
      : endpoints.USERS

    return axios.post(endpoint, user, {
      headers: {
        [config.BOUNCE_PATH]: registrationValidationUrl
      }
    })
  },
  /**
   * User registration validation
   * @param {Object} payload
   * @returns {Promise<AxiosResponse<any>>}
   */
  validateRegister ({ id, token }) {
    return axios.post(endpoints.VALIDATE_REGISTER, {
      userId: Number(id),
      token
    })
  },
  /**
   * Request user password reset
   * @param {string} email
   * @returns {Promise<AxiosResponse<any>>}
   */
  requestPasswordReset (email) {
    return axios.post(
      endpoints.FORGOTTEN_PASSWORD,
      { email },
      {
        headers: {
          [config.BOUNCE_PATH]: resetPasswordUrl
        }
      }
    )
  },
  /**
   * User password change
   * @param {Object} payload
   * @returns {Promise<AxiosResponse<any>>}
   */
  resetPassword ({ id, token, plainPassword }) {
    return axios.post(endpoints.RESET_PASSWORD, {
      plainPassword,
      userId: Number(id),
      token
    })
  },
  /**
   * User email update
   * @param {Object} payload
   * @param {string} payload.user - Current user IRI
   * @param {string} payload.email
   * @param {string} payload.password
   * @returns {Promise<AxiosResponse<any>>}
   */
  updateEmail ({ user, email, password }) {
    return axios.post(endpoints.UPDATE_EMAIL, {
      user,
      email,
      password
    })
  },
  /**
   * User password update
   * @param {Object} payload
   * @param {string} payload.user - Current user IRI
   * @param {string} payload.currentPassword
   * @param {string} payload.newPassword
   * @returns {Promise<AxiosResponse<any>>}
   */
  updatePassword ({ user, currentPassword, newPassword }) {
    return axios.post(endpoints.UPDATE_PASSWORD, {
      user,
      currentPassword,
      newPassword
    })
  },
  /**
   * Get users data
   * @param {Object} params
   * @returns {Promise<AxiosResponse<any>>}
   */
  getUsers (params) {
    return axios.get(endpoints.USERS, { params })
  },
  /**
   * Get user data
   * @param {string} userIRI
   * @returns {Promise<AxiosResponse<any>>}
   */
  getUser (userIRI) {
    return axios.get(userIRI)
  },
  /**
   * User has action (ACL)
   * @param {string} userIRI
   * @param {string} object
   * @param {string} action
   * @returns {Promise<AxiosResponse<any>>}
   */
  getUserHasAction (userIRI, { object, action }) {
    return axios.get(endpoints.USER_HAS_ACTION.replace('{{userIRI}}', userIRI), {
      params: {
        object,
        action
      }
    })
  },
  /**
   * Update user data
   * @param {string} userIRI
   * @param {Object} data
   * @returns {Promise<AxiosResponse<any>>}
   */
  updateUser (userIRI, data) {
    return axios.patch(userIRI, data)
  },
  /**
   * Update user preferred locale
   * @param {string} userIRI
   * @param {string} locale
   * @returns {Promise<AxiosResponse<any>>}
   */
  changePreferredLocale (userIRI, locale) {
    return axios.patch(userIRI, { preferredLocale: locale }).catch(error => error)
  },
  /**
   * Update (add, remove) organizations allowed to user
   * @param {Object} data
   * @returns {Promise<AxiosResponse<any>>}
   */
  updateUserHasOrganizations (data) {
    return axios.post(endpoints.USER_HAS_ORGANIZATIONS, data)
  },
  /**
   * Update (add, remove) websites allowed to user
   * @param {Object} data
   * @returns {Promise<AxiosResponse<any>>}
   */
  updateUserHasWebsites (data) {
    return axios.post(endpoints.USER_HAS_WEBSITES, data)
  }
}
