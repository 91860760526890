import {
  stateTable, gettersTable, mutationsTable, actionsTable
} from '@store/modules/table'
import table from '@enums/table'

export const state = {
  service: {
    name: 'biddersService',
    getItemsMethod: 'getBidders',
    getItemMethod: 'getBidder',
    patchItemMethod: 'updateBidder'
  },
  ...stateTable,
  tableFiltersState: {
    [table.ORDER]: {
      name: table.SORT_ASC
    }
  },
  tableColumnFilters: {
    active: true,
    required: true
  }
}

export const getters = {
  ...gettersTable
}

export const mutations = {
  ...mutationsTable
}

export const actions = {
  ...actionsTable
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
