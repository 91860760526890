import {
  stateTable, gettersTable, mutationsTable, actionsTable
} from '@store/modules/table'
import configStatus from '@enums/configStatus'

export const state = {
  service: {
    name: 'websiteConfigsService',
    getItemsMethod: 'getWebsiteConfigs'
  },
  ...stateTable,
  editedItemId: null,
  showArchived: false,
  hasDraft: false
}

export const getters = {
  ...gettersTable
}

export const mutations = {
  ...mutationsTable,
  /**
   * @param state
   * @param {string|number} id
   */
  setEditedItemId (state, id) {
    state.editedItemId = id
  },
  /**
   * @param state
   * @param {boolean} archived
   */
  setShowArchived (state, archived) {
    state.showArchived = archived
  },
  /**
   * @param state
   * @param {boolean} hasDraft
   */
  setHasDraft (state, hasDraft) {
    state.hasDraft = hasDraft
  }
}

export const actions = {
  ...actionsTable,
  /**
   * Website configs has draft or pending status ?
   * @param commit
   * @param {string} itemIRI
   * @returns {Promise<*>}
   */
  async fetchWebsiteConfigsHasDraft ({ commit }, itemIRI) {
    try {
      const { data } = await this.$services.websiteConfigsService.getWebsiteConfigs({
        website: itemIRI,
        status: [configStatus.DRAFT.value, configStatus.PENDING.value]
      })
      // Keep data in store
      commit('setHasDraft', data?.['hydra:member'].length > 0)

      return data
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
