import axios from '@axios'
import endpoints from '@enums/endpoints'

export default {
  /**
   * Fetch casbin policies values
   * @param {Object} params
   * @returns {Promise<AxiosResponse<any>>}
   */
  getPolicies (params) {
    return axios.get(endpoints.CASBIN_POLICIES, { params })
  }
}
