export default Object.freeze({
  ACCOUNTS: 'accounts',
  ACCOUNTS_EDIT: 'accounts-edit',
  ACCOUNTS_EDIT_GENERAL: 'accounts-edit-general',
  ACCOUNTS_EDIT_ORGANIZATIONS: 'accounts-edit-organizations',
  ACCOUNTS_EDIT_WEBSITES: 'accounts-edit-websites',
  ACL_RULES: 'acl-rules',
  ADS_TXT: 'ads-txt',
  ADS_TXT_CREATE: 'ads-txt-create',
  ADS_TXT_EDIT: 'ads-txt-edit',
  ADS_TXT_EDIT_GENERAL: 'ads-txt-edit-general',
  BIDDERS: 'bidders',
  BIDDERS_CREATE: 'bidders-create',
  BIDDERS_EDIT: 'bidders-edit',
  BIDDERS_EDIT_GENERAL: 'bidders-edit-general',
  CONTRACTS: 'contracts',
  CONTRACTS_EDIT: 'contracts-edit',
  ERROR_404: 'error-404',
  FORGOT_PASSWORD: 'forgot-password',
  HOMEPAGE: 'dashboard',
  LOGIN: 'login',
  ORGANIZATIONS: 'organizations',
  ORGANIZATIONS_CONTRACT: 'organizations-contract',
  ORGANIZATIONS_CREATE: 'organizations-create',
  ORGANIZATIONS_EDIT: 'organizations-edit',
  ORGANIZATIONS_EDIT_ACCOUNTS: 'organizations-edit-accounts',
  ORGANIZATIONS_EDIT_GENERAL: 'organizations-edit-general',
  ORGANIZATIONS_EDIT_WEBSITES: 'organizations-edit-websites',
  PROFILE: 'profile',
  PROFILE_API_TOKENS: 'profile-api-tokens',
  PROFILE_EMAIL: 'profile-email',
  PROFILE_GENERAL: 'profile-general',
  PROFILE_PASSWORD: 'profile-password',
  REGISTER: 'register',
  RESET_PASSWORD: 'reset-password',
  RESOURCE_NOT_FOUND: 'resource-not-found',
  VALIDATE: 'validate',
  WEBSITE_CONFIGURATION_ADS_TXT: 'website-configuration-ads-txt',
  WEBSITE_CONFIGURATION_BIDDERS: 'website-configuration-bidders',
  WEBSITE_CONFIGURATION_CONSUMPTION: 'website-configuration-consumption',
  WEBSITE_CONFIGURATION_CSS: 'website-configuration-css',
  WEBSITE_CONFIGURATION_DYNAMIC: 'website-configuration-dynamic',
  WEBSITE_CONFIGURATION_EDIT: 'website-configuration-edit',
  WEBSITE_CONFIGURATION_IN_IMAGE: 'website-configuration-in-image',
  WEBSITE_CONFIGURATION_NO_ADS: 'website-configuration-no-ads',
  WEBSITE_CONFIGURATION_PRESIZED_DIVS: 'website-configuration-presized-divs',
  WEBSITE_CONFIGURATION_PURGE: 'website-configuration-purge',
  WEBSITE_CONFIGURATION_SCRIPTS: 'website-configuration-scripts',
  WEBSITE_CONFIGURATION_SLOTS: 'website-configuration-slots',
  WEBSITES: 'websites',
  WEBSITES_BIDDERS: 'websites-bidders',
  WEBSITES_CREATE: 'websites-create',
  WEBSITES_EDIT: 'websites-edit',
  WEBSITES_EDIT_ADS_TXT: 'websites-edit-ads-txt',
  WEBSITES_EDIT_CONFIGS: 'websites-edit-configs',
  WEBSITES_EDIT_CONTRACT: 'websites-edit-contract',
  WEBSITES_EDIT_FEES: 'websites-edit-fees',
  WEBSITES_EDIT_GENERAL: 'websites-edit-general',
  WEBSITES_EDIT_MONITORING: 'websites-edit-monitoring',
  WEBSITES_EDIT_PRESIZED_DIVS: 'websites-edit-presized-divs',
  WEBSITES_EDIT_WORDPRESS_DEBUG: 'websites-edit-wordpress-debug'
})
