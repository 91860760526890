import Vue from 'vue'
import VueRouter from 'vue-router'
import views from '@enums/views'
import config from '@enums/config'
import store from '@store'
import { i18n, loadLanguageAsync } from '@i18n'
import { setAxiosHeader } from '@axios'
import { canNavigate } from '@router/canNavigate'

// Routes
import accountsRoutes from '@router/routes/accountsRoutes'
import aclRulesRoutes from '@router/routes/aclRulesRoutes'
import adsTxtRoutes from '@router/routes/adsTxtRoutes'
import biddersRoutes from '@router/routes/biddersRoutes'
import contractsRoutes from '@router/routes/contractsRoutes'
import homePageRoute from '@router/routes/homePageRoute'
import loginRoute from '@router/routes/loginRoute'
import notFoundRoutes from '@router/routes/notFoundRoutes'
import organizationsRoutes from '@router/routes/organizationsRoutes'
import profileManagerRoutes from '@router/routes/profileManagerRoutes'
import profileRoutes from '@router/routes/profileRoutes'
import validateRoutes from '@router/routes/validateRoutes'
import websiteConfigurationRoutes from '@router/routes/websiteConfigurationRoutes'
import websitesRoutes from '@router/routes/websitesRoutes'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to) {
    return to.hash && document.querySelector(to.hash)
      ? document.querySelector(to.hash).scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      })
      : {
        x: 0,
        y: 0
      }
  },
  routes: [
    ...accountsRoutes,
    ...aclRulesRoutes,
    ...adsTxtRoutes,
    ...biddersRoutes,
    ...contractsRoutes,
    ...homePageRoute,
    ...loginRoute,
    ...notFoundRoutes,
    ...organizationsRoutes,
    ...profileManagerRoutes,
    ...profileRoutes,
    ...validateRoutes,
    ...websiteConfigurationRoutes,
    ...websitesRoutes,
    {
      path: '',
      redirect: {
        name: views.HOMEPAGE
      }
    },
    {
      path: '*',
      redirect: {
        name: views.ERROR_404
      }
    }
  ]
})

let redirectToSource

router.beforeEach(async (to, from, next) => {
  // If user is authenticated
  if (store.getters['auth/isAuthenticated']) {
    // And if the route he is trying to access is public-only, we abort navigation
    if (to.meta?.publicOnly) {
      /** If there isn't any previous navigation, we redirect to homepage,
       * otherwise, we abort navigation */
      return from.name ? next(false) : next({ name: views.HOMEPAGE })
    }

    // Unlock website config if navigating out of it
    if (!to.meta?.canBeLocked) await store.dispatch('websiteConfig/unlock')

    // Force default locale to en if route can't change locale
    if (to.meta?.cannotChangeLocale && i18n.global.locale.value !== config.DEFAULT_LANGUAGE) {
      loadLanguageAsync(config.DEFAULT_LANGUAGE)
      setAxiosHeader('Accept-Language', config.DEFAULT_LANGUAGE)
    }

    // Route protection with ACL
    return canNavigate(to) ? next() : next({ name: views.HOMEPAGE })
  }
  /** Unauthenticated user navigation will be confirmed if the route is public/public-only
   * or is the login route */
  if (to.meta?.public || to.meta?.publicOnly || to.name === views.LOGIN) return next()

  // Save page to redirect user when he logs in (only once to avoid loosing original query)
  if (!redirectToSource) {
    redirectToSource = to.fullPath
  }

  // Finally, unauthenticated user is redirect to login page or specific page set in url
  return next({
    name: views.LOGIN,
    query: { redirect: btoa(redirectToSource) }
  })
})

export default router
