import {
  stateTable, gettersTable, mutationsTable, actionsTable
} from '@store/modules/table'

export const state = {
  service: {
    name: 'aclRulesService',
    getItemsMethod: 'getAclRules'
  },
  ...stateTable
}

export const getters = {
  ...gettersTable
}

export const mutations = {
  ...mutationsTable
}

export const actions = {
  ...actionsTable
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
