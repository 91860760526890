const cpm = 'cpm'
const percent = 'percent'

export default Object.freeze({
  ACTIRISE_FEES_DEFAULT: 30,
  ADSERVER_FEES_DEFAULT: 0.3,
  CONTRACT_DURATION_DEFAULT: 1,
  CPM: cpm,
  EXTERNAL_FEES_DEFAULT: 0.3,
  FEES_TYPE_OPTIONS: [
    {
      value: percent,
      text: '% (percent)'
    },
    {
      value: cpm,
      text: '€ (cpm)'
    }
  ],
  MIN_VALUE_CONTRACT_DURATION: 1,
  MAX_VALUE_CPM: 5,
  MIN_VALUE_CPM: 0,
  MAX_VALUE_FEES_PERCENT: 80,
  MIN_VALUE_FEES_PERCENT: 5,
  MAX_VALUE_TRIAL_DURATION: 90,
  MIN_VALUE_TRIAL_DURATION: 0,
  PERCENT: percent,
  STATUS: {
    // Key label match with contract i18n locales
    // Keep order
    NO_CONTRACT: {
      value: 'no_contract',
      label: 'contract.no_contract',
      variant: 'dark'
    },
    UNKNOWN: {
      value: 'unknown',
      label: 'contract.unknown',
      variant: 'secondary'
    },
    UNSIGNED: {
      value: 'unsigned',
      label: 'contract.unsigned',
      variant: 'warning'
    },
    SIGNED: {
      value: 'signed',
      label: 'contract.signed',
      variant: 'success'
    },
    TERMINATED: {
      value: 'terminated',
      label: 'contract.terminated',
      variant: 'danger'
    }
  },
  TRIAL_DURATION_DEFAULT: 30
})
