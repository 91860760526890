import {
  stateTable, gettersTable, mutationsTable, actionsTable
} from '@store/modules/table'
import isObject from '@utils/isObject'
import table from '@enums/table'

export const state = {
  service: {
    name: 'usersService',
    getItemsMethod: 'getUsers',
    getItemMethod: 'getUser',
    patchItemMethod: 'updateUser'
  },
  ...stateTable,
  tableFiltersState: {
    [table.ORDER]: {
      firstName: table.SORT_ASC
    }
  },
  tableColumnFilters: {
    active: true
  }
}

export const getters = {
  ...gettersTable,
  /**
   * @param state
   */
  editedAccountFullName (state) {
    if (!isObject(state.editedItem)) {
      return ''
    }
    return `${state.editedItem?.firstName} ${state.editedItem?.lastName}`
  }
}

export const mutations = {
  ...mutationsTable,
  /**
   * Set if specific resource can be allowed to user
   * @param state
   * @param {boolean} enable
   * @param {string} key (allocableToOrganizations, allocableToWebsites)
   */
  setAllocableToResource (state, { enable, key }) {
    state.editedItem = {
      ...state.editedItem,
      [key]: enable
    }
  }
}

export const actions = {
  ...actionsTable
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
