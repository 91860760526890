// Key label match with common i18n locales
export default Object.freeze({
  PAGE: {
    value: 'page',
    label: 'common.page'
  },
  SESSION: {
    value: 'session',
    label: 'common.session'
  }
})
