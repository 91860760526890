import { defineAbility } from '@casl/ability'
import Vue from 'vue'

// eslint-disable-next-line consistent-return
export const useAbility = () => defineAbility(can => {
  const store = Vue.prototype.$store
  if (store.getters['user/isSuperAdmin']) {
    // For super admin only, we can use this rule too => ability.can(aclActions.ACTION, aclObjects.ANY)
    return can('manage', 'all')
  }

  const { permissions } = store.state.user
  permissions.forEach(permission => can(permission.action, permission.object, permission.fields ?? ['default_field']))
})

// eslint-disable-next-line no-return-assign
export const useAbilityTemplate = () =>
  Vue.prototype.$can = (action, object, fields) => (action && object ? useAbility().can(action, object, fields) : true)
