import axios from '@axios'
import endpoints from '@enums/endpoints'
import apiTokenStatus from '@enums/apiTokenStatus'

export default {
  /**
   * Fetch api tokens values
   * @param {Object} params
   * @returns {Promise<AxiosResponse<any>>}
   */
  getApiTokens (params) {
    return axios.get(endpoints.API_TOKENS, { params })
  },
  /**
   * Create new token
   * @param {string} name
   * @returns {Promise<AxiosResponse<any>>}
   */
  createApiToken (name) {
    return axios.post(endpoints.API_TOKENS, { name })
  },
  /**
   * Revoke a token
   * @param {string} apiTokenIRI
   * @returns {Promise<AxiosResponse<any>>}
   */
  revokeApiToken (apiTokenIRI) {
    return axios.patch(apiTokenIRI, {
      status: apiTokenStatus.REVOKED
    })
  }
}
