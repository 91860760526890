import { set, unref } from '@vue/composition-api'

export const stateAPI = {
  itemsByIRIs: {},
  loading: false
}

export const gettersAPI = {
  /**
   * @param state
   * @returns {Array}
   */
  items: state => Object.values(state.itemsByIRIs),
  /**
   * @param state
   * @returns {Array}
   */
  itemIRIs: state => Object.keys(state.itemsByIRIs),
  /**
   * @param state
   * @returns {(function(*): ([]|*))|*}
   */
  fetchedItems: state => itemIRIs => {
    const rawItemIRIs = unref(itemIRIs)
    if (!rawItemIRIs?.value?.length) return []
    return rawItemIRIs?.value.reduce((items, itemId) => {
      const item = state.itemsByIRIs[itemId]
      if (item) items.push(item)
      return items
    }, [])
  }
}

export const mutationsAPI = {
  /**
   * @param state
   * @param {Object} item
   */
  setItemById (state, item) {
    if (!item) return
    set(state.itemsByIRIs, item['@id'], item)
  },
  /**
   * @param state
   * @param {boolean} loading
   */
  setLoading (state, loading) {
    state.loading = loading
  }
}

export const actionsAPI = {
  /**
   * Fetch items from the API then store them in the state
   * @param context
   * @param {Object} params
   * @returns {Promise<AxiosResponse<any>>}
   */
  async fetchItems ({ state, dispatch }, params) {
    const rawParams = unref(params)
    const response = await this.$axios.get(state.endpoint, { params: rawParams })
    const fetchedItemIRIs = dispatch('setItemsByIRIs', response?.data?.['hydra:member'])
    return {
      response,
      fetchedItemIRIs
    }
  },
  /**
   * Iterate on provided items, store each one of them in the state
   * and return an array of their IRIs
   * @param context
   * @param {Array} items
   */
  setItemsByIRIs ({ commit }, items) {
    if (!items?.length) return []
    return items.map(item => {
      commit('setItemById', item)
      return item['@id']
    })
  }
}
