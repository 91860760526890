// Import core styles
import '@core/scss/core.scss'
// Import assets styles
import '@/assets/scss/style.scss'
// Import toastification styles
import '@core/scss/vue/libs/toastification.scss'

import Vue from 'vue'

// Plugins registration
import '@plugins'

// Directives registration
import '@directives'

// Global const for acl usage
import aclActions from '@enums/aclActions'
import aclObjects from '@enums/aclObjects'
import aclFields from '@enums/aclFields'

// Global Components registration
import './global-components'

import { loadLanguageAsync, i18n } from '@i18n'
import config from '@enums/config'
import createApp from './app'

// Vue configuration
const debug = config.ENVIRONMENT !== 'production'
Vue.config.productionTip = !debug // To prevent the production tip on Vue startup
Vue.config.silent = !debug // Suppress all Vue logs and warnings
Vue.config.devtools = debug // Authorizes inspection vue-devtools
Vue.config.performance = debug // Enable component init, compile, render and patch performance

// For acl
Vue.prototype.$aclActions = aclActions
Vue.prototype.$aclObjects = aclObjects
Vue.prototype.$aclFields = aclFields

const start = async () => {
  const { app, store } = createApp()

  app.use(i18n)
  Vue.prototype.$store = store

  try {
    await Promise.all([
      loadLanguageAsync(i18n.locale),
      store.dispatch('auth/authenticate')
    ])
  } catch (error) {
    // eslint-disable-next-line no-console
    if (config.ENVIRONMENT === 'development') console.info(error?.message)
  } finally {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
      appLoading.style.display = 'none'
    }
  }

  app.mount('#app')
}

start()
