import {
  stateTable, gettersTable, mutationsTable, actionsTable
} from '@store/modules/table'
import table from '@enums/table'
import adsTxt from '@enums/adsTxt'

export const state = {
  service: {
    name: 'adsTxtService',
    getItemsMethod: 'getAdsTxts',
    getItemMethod: 'getAdsTxt',
    patchItemMethod: 'updateAdsTxt'
  },
  ...stateTable,
  tableFiltersState: {
    [table.ORDER]: {
      [adsTxt.BIDDER_NAME]: table.SORT_ASC
    }
  },
  tableColumnFilters: {
    [adsTxt.BIDDER_ACTIVE]: true
  },
  bidderName: null,
  bidderItems: null
}

export const getters = {
  ...gettersTable,
  /**
   * @param state
   */
  bidderName (state) {
    return state.bidderName
  }
}

export const mutations = {
  ...mutationsTable,
  /**
   * @param state
   * @param {string} bidderName
   */
  setBidderName (state, bidderName) {
    state.bidderName = bidderName
  },
  /**
   * @param state
   * @param {Array} bidderItems
   */
  setBidderItems (state, bidderItems) {
    state.bidderItems = bidderItems
  }
}

export const actions = {
  ...actionsTable
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
