import {
  stateTable, gettersTable, mutationsTable, actionsTable
} from '@store/modules/table'
import table from '@enums/table'
import websiteProducts from '@enums/websiteProducts'

export const state = {
  service: {
    name: 'websitesService',
    getItemsMethod: 'getWebsites',
    getItemMethod: 'getWebsite',
    patchItemMethod: 'updateWebsite'
  },
  ...stateTable,
  tableFiltersState: {
    [table.ORDER]: {
      name: table.SORT_ASC
    }
  },
  websitesScraping: undefined,
  keepIRISerializedData: false
}

export const getters = {
  ...gettersTable,
  /**
   * @param state
   * @returns {boolean}
   */
  activeWebsite (state) {
    return state.editedItem?.active
  },
  /**
   * @param state
   * @returns {boolean}
   */
  flashbidProduct (state) {
    return [websiteProducts.SDK, websiteProducts.WORDPRESS_PLUGIN].includes(state.editedItem?.websiteProduct?.['@id'])
  }
}

export const mutations = {
  ...mutationsTable,
  /**
   * @param state
   * @param {string} websitesScraping
   */
  setWebsitesScraping (state, websitesScraping) {
    state.websitesScraping = websitesScraping
  }
}

export const actions = {
  ...actionsTable,
  /**
   * Scrap websites
   * @param state
   * @param commit
   * @param {string} websiteIRI
   * @param {Object} data
   * @returns {Promise<*>}
   */
  // eslint-disable-next-line no-empty-pattern
  async scrapWebsite ({ state, commit }, { websiteIRI, data }) {
    // The request is made only once per session
    if (state.websitesScraping) {
      const scrapingData = JSON.parse(state.websitesScraping)
      if (scrapingData.includes(websiteIRI)) return
      // Add website IRI in session storage
      scrapingData.push(websiteIRI)
      commit('setWebsitesScraping', JSON.stringify(scrapingData))
    } else {
      commit('setWebsitesScraping', JSON.stringify([websiteIRI]))
    }

    await this.$services.websitesService.scrapWebsite(websiteIRI, data)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
