import {
  stateTable, gettersTable, mutationsTable, actionsTable
} from '@store/modules/table'
import table from '@enums/table'
import apiTokenStatus from '@enums/apiTokenStatus'

export const state = {
  service: {
    name: 'apiTokensService',
    getItemsMethod: 'getApiTokens',
    patchItemMethod: 'updateApiToken'
  },
  ...stateTable,
  tableFiltersState: {
    [table.ORDER]: {
      name: table.SORT_ASC
    }
  },
  tableColumnFilters: {
    status: apiTokenStatus.ACTIVE
  }
}

export const getters = {
  ...gettersTable
}

export const mutations = {
  ...mutationsTable
}

export const actions = {
  ...actionsTable
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
