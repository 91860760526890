import axios from '@axios'
import endpoints from '@enums/endpoints'

export default {
  /**
   * Fetch userGroups values
   * @param {Object} params
   * @returns {Promise<AxiosResponse<any>>}
   */
  getUserGroups (params) {
    return axios.get(endpoints.USER_GROUPS, { params })
  },
  /**
   * Create user group
   * @param {Object} data
   * @returns {Promise<AxiosResponse<any>>}
   */
  createUserGroups (data) {
    return axios.post(endpoints.USER_GROUPS, data)
  },
  /**
   * Delete user group
   * @param {string} userGroupsIRI
   * @returns {Promise<AxiosResponse<any>>}
   */
  deleteUserGroups (userGroupsIRI) {
    return axios.delete(userGroupsIRI)
  }
}
