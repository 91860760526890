import {
  stateTable, gettersTable, mutationsTable, actionsTable
} from '@store/modules/table'
import table from '@enums/table'

export const state = {
  service: {
    name: 'organizationsService',
    getItemsMethod: 'getOrganizations',
    getItemMethod: 'getOrganization',
    patchItemMethod: 'updateOrganization'
  },
  ...stateTable,
  tableFiltersState: {
    [table.ORDER]: {
      name: table.SORT_ASC
    }
  },
  allItems: undefined
}

export const getters = {
  ...gettersTable
}

export const mutations = {
  ...mutationsTable,
  /**
   * @param state
   * @param {Array} items
   */
  setAllItems (state, items) {
    state.allItems = items
  }
}

export const actions = {
  ...actionsTable,
  /**
   * Fetch all items values
   * @param context
   * @param {Object} params
   * @returns {Promise<any>}
   */
  async fetchAllItems ({ state, commit }, params) {
    try {
      const response = await this.$services[state.service.name][state.service.getItemsMethod](params)
      // Keep data in store
      commit('setAllItems', response?.data?.['hydra:member'] ?? [])

      return response
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
