import {
  state as stateWebsites,
  getters as gettersWebsites,
  mutations as mutationsWebsites,
  actions as actionsWebsites
} from '@store/modules/websites'

export const state = {
  ...stateWebsites,
  tableColumnFilters: {},
  editedItemId: null
}

export const getters = {
  ...gettersWebsites
}

export const mutations = {
  ...mutationsWebsites,
  /**
   * @param state
   * @param {string|number} id
   */
  setEditedItemId (state, id) {
    state.editedItemId = id
  }
}

export const actions = {
  ...actionsWebsites
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
