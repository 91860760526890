import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'
import {
  ModalPlugin, TooltipPlugin, VBTogglePlugin, BootstrapVueIcons
} from 'bootstrap-vue'
import VueMeta from 'vue-meta'
import VueFlags from '@growthbunker/vueflags'
import PortalVue from 'portal-vue'

// Composition API
Vue.use(VueCompositionAPI)

// Vue meta
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

// BSV plugins registration
Vue.use(ModalPlugin)
Vue.use(TooltipPlugin)
Vue.use(VBTogglePlugin)

// BSV icons registration
Vue.use(BootstrapVueIcons)

// Vue flags lib (https://www.growthbunker.dev/vueflags/?ref=madewithvuejs.com)
Vue.use(VueFlags, {
  iconPath: '/vueflags/'
})

// Portal vue (https://v2.portal-vue.linusb.org/)
Vue.use(PortalVue)
