import endpoints from '@enums/endpoints'

export const state = {
  items: undefined,
  loading: false
}

export const getters = {}

export const mutations = {
  set (state, { key, value }) {
    state[key] = value
  }
}

export const actions = {
  /**
   * Fetch items values
   * @param commit
   * @returns {Promise<any>}
   */
  async fetchItems ({ commit }) {
    try {
      commit('set', {
        key: 'loading',
        value: true
      })
      const { data: { 'hydra:member': currenciesItems } } = await this.$axios.get(endpoints.CURRENCIES)
      commit('set', {
        key: 'items',
        value: currenciesItems || undefined
      })

      return currenciesItems
    } catch (error) {
      return Promise.reject(error)
    } finally {
      commit('set', {
        key: 'loading',
        value: false
      })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
