export default Object.freeze({
  ARCHIVED: {
    value: 'archived',
    variant: 'secondary'
  },
  DRAFT: {
    value: 'draft',
    variant: 'primary'
  },
  PENDING: {
    value: 'pending',
    variant: 'warning'
  },
  PUBLISHED: {
    value: 'published',
    variant: 'success'
  }
})
