import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import { createVuexHelpers } from 'vue2-helpers'
import analyticsEnums from '@enums/analytics'

// Modules
import accounts from '@store/modules/accounts'
import aclRules from '@store/modules/aclRules'
import adsTxt from '@store/modules/adsTxt'
import analytics from '@store/modules/analytics'
import apiTokens from '@store/modules/apiTokens'
import app from '@store/modules/app'
import appConfig from '@store/modules/appConfig'
import auth from '@store/modules/auth'
import bidders from '@store/modules/bidders'
import contracts from '@store/modules/contracts'
import countries from '@store/modules/countries'
import currencies from '@store/modules/currencies'
import genders from '@store/modules/genders'
import organizations from '@store/modules/organizations'
import organizationsAccounts from '@store/modules/organizationsAccounts'
import organizationTypes from '@store/modules/organizationTypes'
import organizationsWebsites from '@store/modules/organizationsWebsites'
import pendingTagActivation from '@store/modules/pendingTagActivation'
import roles from '@store/modules/roles'
import unpublishedDraft from '@store/modules/unpublishedDraft'
import user from '@store/modules/user'
import verticalMenu from '@store/modules/verticalMenu'
import websites from '@store/modules/websites'
import websiteConfig from '@store/modules/websiteConfig'
import websiteConfigsList from '@store/modules/websiteConfigsList'
import websiteProducts from '@store/modules/websiteProducts'

Vue.use(Vuex)

const storageName = process.env.VUE_APP_STORAGE_NAME
const storage = localStorage

const vuexPersist = new VuexPersistence({
  key: storageName,
  strictMode: process.env.VUE_APP_ENVIRONMENT !== 'production',
  storage,
  reducer: state => ({
    user: {
      rememberMyEmail: state.user?.rememberMyEmail,
      rememberedEmail: state.user?.rememberedEmail
    },
    analytics: {
      [analyticsEnums.TIMEZONE]: state.analytics[analyticsEnums.TIMEZONE]
    },
    verticalMenu: {
      isVerticalMenuCollapsed: state.verticalMenu?.isVerticalMenuCollapsed
    }
  })
})

const vuexPersistSession = new VuexPersistence({
  key: storageName,
  strictMode: process.env.VUE_APP_ENVIRONMENT !== 'production',
  storage: sessionStorage,
  reducer: state => ({
    websites: {
      websitesScraping: state.websites.websitesScraping
    }
  })
})

export default new Vuex.Store({
  modules: {
    accounts,
    aclRules,
    adsTxt,
    analytics,
    apiTokens,
    app,
    appConfig,
    auth,
    bidders,
    contracts,
    countries,
    currencies,
    genders,
    organizations,
    organizationsAccounts,
    organizationTypes,
    organizationsWebsites,
    pendingTagActivation,
    roles,
    unpublishedDraft,
    user,
    verticalMenu,
    websites,
    websiteConfig,
    websiteConfigsList,
    websiteProducts
  },
  strict: process.env.VUE_APP_ENVIRONMENT !== 'production',
  mutations: {
    RESTORE_MUTATION: vuexPersist.RESTORE_MUTATION,
    setStoreInitialization (state) {
      // Check if item exist
      if (storage.getItem(storageName)) {
        // Replace the state object with the stored item
        this.replaceState(Object.assign(state, JSON.parse(storage.getItem(storageName))))
      }
    }
  },
  plugins: [vuexPersist.plugin, vuexPersistSession.plugin]
})

// Vuex helpers
export const {
  useState, useGetters, useMutations, useActions
} = createVuexHelpers()
