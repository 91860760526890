import Vue from 'vue'
import axios from 'axios'
import qs from 'qs'
import endpoints from '@enums/endpoints'
import config from '@enums/config'
import statusCode from '@enums/statusCode'
import store from '@store'
import { i18n } from '@i18n'

const axiosInstance = axios.create({
  baseURL: endpoints.API_URL,
  headers: {
    common: {
      Accept: 'application/ld+json, application/json, text/html',
      'Accept-Language': i18n.global.locale.value
    }
  },
  paramsSerializer: params => qs.stringify(params)
})

axiosInstance.interceptors.request.use(
  configuration => {
    // Set API version
    if (!configuration.url.includes(config.API_VERSION)) {
      Object.assign(configuration, {
        url: config.API_VERSION + configuration.url
      })
    }
    // Apply correct Content-type header in case of a PATCH request
    if (configuration.method === 'patch') {
      // eslint-disable-next-line no-param-reassign
      configuration.headers['Content-Type'] = 'application/merge-patch+json'
    }
    return configuration
  },
  error => Promise.reject(error)
)

// Redirect to login page when request fail with a 401 response
axiosInstance.interceptors.response.use(
  response => response,
  async error => {
    if (error?.response?.status === statusCode.UNAUTHORIZED) {
      await store.dispatch('auth/logout', true)
    }
    return Promise.reject(error)
  }
)

Vue.prototype.$http = axiosInstance

/**
 * @param {string} header
 * @param {string} locale
 */
export const setAxiosHeader = (header, locale) =>
  Reflect.set(axiosInstance.defaults.headers.common, header, locale)

/**
 * @param {string} header
 */
export const removeAxiosHeader = header =>
  Reflect.deleteProperty(axiosInstance.defaults.headers.common, header)

const defaultBounceURLParams = '?id={id}&token={token}'

/**
 * Build bounce url sent to the API and used for email redirection
 * @param {string} path
 * @param {string} [URLParams]
 * @returns {string} - Bounce url
 */
export const getBounceUrl = (path, URLParams = defaultBounceURLParams) =>
  `${path}${URLParams}`

export default axiosInstance
