import axios from '@axios'
import endpoints from '@enums/endpoints'

export default {
  /**
   * Fetch websites values
   * @param {Object} params
   * @returns {Promise<AxiosResponse<any>>}
   */
  getWebsites (params) {
    return axios.get(endpoints.WEBSITES, { params })
  },
  /**
   * Get website values
   * @param {string} websiteIRI
   * @returns {Promise<AxiosResponse<any>>}
   */
  getWebsite (websiteIRI) {
    return axios.get(websiteIRI)
  },
  /**
   * Update website values
   * @param {string} websiteIRI
   * @param {Object} data
   * @returns {Promise<AxiosResponse<any>>}
   */
  updateWebsite (websiteIRI, data) {
    return axios.patch(websiteIRI, data)
  },
  /**
   * Create website
   * @param {Object} data
   * @returns {Promise<AxiosResponse<any>>}
   */
  createWebsite (data) {
    // eslint-disable-next-line no-param-reassign
    if (data.active) delete data.active
    return axios.post(endpoints.WEBSITES, data)
  },
  /**
   * Update (add, remove) website allowed users values
   * @param {Object} data
   * @returns {Promise<AxiosResponse<any>>}
   */
  updateWebsiteAllowedUsers (data) {
    return axios.post(endpoints.WEBSITE_ALLOWED_USERS, data)
  },
  /**
   * Get website ads.txt monitoring values
   * @param {string} websiteIRI
   * @returns {Promise<AxiosResponse<any>>}
   */
  getWebsiteMonitoring (websiteIRI) {
    return axios.get(endpoints.WEBSITES_ADS_TXT_MONITORING.replace('{{websiteIRI}}', websiteIRI))
  },
  /**
   * Scrap website
   * @param {string} websiteIRI
   * @param {Website} [data={}]
   * @returns {Promise<AxiosResponse<any>>}
   */
  scrapWebsite (websiteIRI, data = {}) {
    return axios.patch(endpoints.WEBSITES_SCRAP.replace('{{websiteIRI}}', websiteIRI), data)
  },
  /**
   * Fetch website products values
   * @param {Object} params
   * @returns {Promise<AxiosResponse<any>>}
   */
  getWebsiteProducts (params) {
    return axios.get(endpoints.WEBSITE_PRODUCTS, { params })
  }
}
