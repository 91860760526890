// Key label match with common i18n locales
export default Object.freeze({
  COMPANY: {
    value: 'Company',
    label: 'common.company'
  },
  INDIVIDUAL: {
    value: 'Individual',
    label: 'common.individual'
  }
})
