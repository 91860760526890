import {
  stateTable, gettersTable, mutationsTable, actionsTable
} from '@store/modules/table'
import configStatus from '@enums/configStatus'

export const state = {
  service: {
    name: 'contractsService',
    getItemsMethod: 'getContracts',
    getItemMethod: 'getContract',
    patchItemMethod: 'updateContract'
  },
  ...stateTable,
  showArchived: false,
  hasDraft: false
}

export const getters = {
  ...gettersTable
}

export const mutations = {
  ...mutationsTable,
  /**
   * @param state
   * @param {boolean} archived
   */
  setShowArchived (state, archived) {
    state.showArchived = archived
  },
  /**
   * @param state
   * @param {boolean} hasDraft
   */
  setHasDraft (state, hasDraft) {
    state.hasDraft = hasDraft
  }
}

export const actions = {
  ...actionsTable,
  /**
   * Contracts has draft or pending status ?
   * @param commit
   * @returns {Promise<*>}
   */
  async fetchContractsHasDraft ({ commit }) {
    try {
      const { data } = await this.$services.contractsService.getContracts({
        status: [configStatus.DRAFT.value, configStatus.PENDING.value]
      })
      // Keep data in store
      commit('setHasDraft', data?.['hydra:member'].length > 0)

      return data
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
